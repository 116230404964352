
























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ExchangeRateDialogue from "@/vue/components/dialogues/ExchangeRateDialogue.vue";
import utils from "@/utilities/Utils";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { USDRate, IUSDRate } from "@/model/USDRate";
import { StateChanger } from "vue-infinite-loading";

@Component({
    components: { 
        ApiButton,
        ExchangeRateDialogue
    }
})

export default class ExchangeRates extends Vue {

    //
    // -- properties
    //

    private rateHeaders = [
        { text: "Rate", value: "rate", sortable: true },
        { text: "Effective From Date", value: "effectiveFromDate", sortable: true },
        { text: "Created Date", value: "createdDate", sortable: false }
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private rateList: Array<USDRate> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    //
    // -- methods
    //

    refreshSearch(): void {
        this.rateList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    addNew(): void {
        const dialog: ExchangeRateDialogue = this.$refs.exchangeRateDialogue as ExchangeRateDialogue;
        dialog.open();
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/admin/searchRates", this.searchParameters);
        if (response.list.length) {
            this.rateList.push(...response.list.map((r: IUSDRate) => new USDRate(r)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
