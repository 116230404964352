






























import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import ApiButton from "@/vue/components/ApiButton.vue";
import DatePicker from "@/vue/components/DatePicker.vue"
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { USDRate } from "@/model/USDRate";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        DatePicker
    } 
})

export default class ExchangeRateDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("rateForm") private readonly rateForm!: VForm;  

    private showDialogue: boolean = false;
    private rate: USDRate = new USDRate();

    //
    // -- methods
    //

    open(): void {
        this.reset();
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.rate);
        this.rateForm?.resetValidation();
    }

    private async save() {
        if (!this.rateForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const response: ISaveResponse = await apiClient.post("/api/admin/saveRate", this.rate, "rate-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }
}
