import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IUSDRate {
    id: string;
    rate: number;
    effectiveFromDate: Date;
    createdDate: Date;
    createdByUserID: string;
}

export class USDRate implements IUSDRate {

    constructor(data?: IUSDRate) {
        if (data) this.update(data);
    }

    update(data: IUSDRate): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    rate: number = 0;
    effectiveFromDate: Date = new Date(utils.emptyDateValue);
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID = utils.emptyGuidValue;
}